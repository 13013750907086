import React from 'react';
import Placeholder from '../../../core/Components/Placeholder';

const ColumnContainer = ({ rendering }) => {
  return (
    <div className="l-content-column l-padding">
      <Placeholder name="column-content" rendering={rendering} />
    </div>
  );
};

export default ColumnContainer;
